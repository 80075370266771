var __tnt = window.__tnt || {};

(function(w, d, $, __tnt, undefined) {

    var me = __tnt.ads || (__tnt.ads = {}),
        utils = {};

    me.curl = function(ad) {
        var smallImage = '<div class="small-ad"><img alt="advertisement" title="' + utils.setTitle(ad.width, ad.height, ad.type) + '" src="'+ ad.smallImage +'"/></div>',
            largeImage = '<div class="large-ad"><img alt="advertisement" title="' + utils.setTitle(ad.width, ad.height, ad.type) + '" src="'+ ad.largeImage +'"/></div>',
            html = '';

        if (ad.clickuri) {
            var clickuriencoded = ad.clickuri.replace(/"/g, '&quot;');
            html = '<div class="hidden-sm hidden-xs page-curl page-curl-image"><a target="_blank" href="'+ clickuriencoded +'"><div class="cover"></div></a>'+ smallImage + largeImage +'</div>';
        } else {
            html = '<div class="hidden-sm hidden-xs page-curl page-curl-image"><div class="cover"></div>'+ smallImage + largeImage +'</div>';
        }
        utils.paint(ad, html);
    };

    me.expandable = function(ad) {
        var small = '<div class="tnt-expandable-small" style="cursor: pointer"><img alt="advertisement" title="' + utils.setTitle(ad.width, ad.height, ad.type) + '" src="' + ad.smallImage + '"/></div>',
            large = '<div class="tnt-expandable-big" style="display: none;"><img alt="advertisement" title="' + utils.setTitle(ad.width, ad.height, ad.type) + '" src="' + ad.largeImage + '" /></div>',
            html = small + large;

        utils.paint(ad, html)
            .then(utils.setWrapper(ad))
            .then(utils.setLink(ad))
            .then(utils.setTrigger(ad))
            .then();
    };

    me.html = function(ad) {
        if (!ad.width) ad.width = $(ad.positionDiv).closest('.tncms-region-ads').prev().data('tnt-ads-tmp').width;
        utils.paint(ad, ad.html);
    };

    me.image = function(ad) {
        var clickuriencoded = '',
            html = '';

        if (typeof ad.clickuri !== 'undefined') clickuriencoded = ad.clickuri.replace(/"/g, '&quot;'); // TODO remove once admanager sends safe uri

        if (ad.display == 'wallpaper') {
            var wallpaperHeight = ad.height,
                bodyTag = d.getElementsByTagName('body')[0];

            bodyTag.style.backgroundImage = 'url('+ ad.asseturl + ')';
            bodyTag.style.height = wallpaperHeight + 'px';
            bodyTag.className += ' wallpaper-ad ';
            if (ad.clickuri) html += '<a href="' + ad.clickuri + '" target="' + ad.target + '" style="width:100%; height: 100%; display:block"></a>';
        } else if (ad.display == 'anchor') {
            var closeIcon = 'fas tnt-times';
            html = (ad.clickuri === '') ? '<a href="#" class="close" data-dismiss="alert"><span class="' + closeIcon + '"></span></a><img alt="advertisement" title="' + utils.setTitle(ad.width, ad.height, ad.type) + '" style="width: ' + ad.width +'px; height: auto; " src="' + ad.asseturl + '" />' : '<a href="#" class="close" data-dismiss="alert"><span class="' + closeIcon + '"></span></a><a href="' + clickuriencoded + '" target="' + ad.target + '" style="display:block"><img alt="advertisement" title="' + utils.setTitle(ad.width, ad.height, ad.type) + '" style="width: ' + ad.width +'px; height: auto; " src="' + ad.asseturl + '" /></a>';
        } else {
            html = (ad.clickuri === '') ? '<img alt="advertisement" title="' + utils.setTitle(ad.width, ad.height, ad.type) + '" src="' + ad.asseturl + '" style="width: 100%;" />' : '<a href="' + clickuriencoded + '" target="' + ad.target + '" style="display:block"><img alt="advertisement" title="' + utils.setTitle(ad.width, ad.height, ad.type) + '" src="' + ad.asseturl + '" style="width: 100%;" /></a>';
        }

        utils.paint(ad, html);
    };

    me.text = function(ad) {
        var flagText = utils.overline(ad),
            html = '';

        if (!ad.width) ad.width = $(ad.positionDiv).closest('.tncms-region-ads').prev().data('tnt-ads-tmp').width;

        if (typeof flagText === 'undefined' || flagText === null) flagText = 'Advertisement';

        if (ad.clickuri) {
            var clickuriencoded = ad.clickuri.replace(/"/g, '&quot;'); // TODO remove once admanager sends safe uri
            html = '<div class="tnt-text-ad-container"><a href="' + clickuriencoded + '" target="' + ad.target + '">' + ad.title + '</a><br/>' + ad.text + '<br/>' + '<span class="tnt-text-ad-flag">' + flagText + '</span></div>';
        } else {
            html = '<div class="tnt-text-ad-container"><strong>' + ad.title + '</strong><br/>' + ad.text + '<br/>' + '<span class="tnt-text-ad-flag">' + flagText + '</span></div>';
        }

        utils.paint(ad, html);
    };

    me.popup = function(ad) {
        var oBlackOutDiv = d.createElement('div'),
            html = '';

        oBlackOutDiv.setAttribute('id', 'BlackOutDiv');
        oBlackOutDiv.style.position = 'fixed';
        oBlackOutDiv.style.top = 0;
        oBlackOutDiv.style.left = 0;
        oBlackOutDiv.style.width = '100%';
        oBlackOutDiv.style.height = '100%';
        oBlackOutDiv.style.zIndex = 10000001;
        oBlackOutDiv.style.backgroundColor = 'black';
        oBlackOutDiv.style.opacity = 0.9;
        oBlackOutDiv.style.filter = 'alpha(opacity=90)';

        html += '<span><i class="fas tnt-times tnt-2x"></i></span>';

        if (ad.type == 'video') {
            var filetype = "video/mp4";

            if (ad.asseturl.match(/\.flv\?/) != null) filetype = "video/x-flv";
            if (ad.clickuri) html += '<a style="display: block; height: 100%; position: absolute; width: 100%;" target="_blank" href="'+ ad.clickuri +'"></a>';
            html += '<video class="tnt-popup-video" autoplay><source src="'+ad.asseturl+'" type="'+filetype+'"/></video>';
        } else if (ad.type == 'text') {
            var flagText = utils.overline(ad);

            if (typeof flagText === 'undefined' || flagText === null) flagText = 'Advertisement';
            if (ad.clickuri) {
                var clickuriencoded = ad.clickuri.replace(/"/g, '&quot;'); // TODO remove once admanager sends safe uri
                html += '<div class="tnt-text-ad-container"><a href="' + clickuriencoded + '" target="' + ad.target + '">' + ad.title + '</a><br/>' + ad.text + '<br/>' + '<span class="tnt-text-ad-flag">' + flagText + '</span></div>';
            } else {
                html += '<div class="tnt-text-ad-container"><strong>' + ad.title + '</strong><br/>' + ad.text + '<br/>' + '<span class="tnt-text-ad-flag">' + flagText + '</span></div>';
            }
        } else if (ad.type == 'image') {
            var url = '';

            if (typeof ad.clickuri !== "undefined") url = 'http://' + ad.clickuri.replace(/"/g, '&quot;');
            html += (ad.clickuri === '') ? '<img alt="advertisement" title="' + utils.setTitle(ad.width, ad.height, ad.type) + '" class="img-responsive" width="'+ ad.width +'" height="'+ ad.height +'" src="'+ ad.asseturl +'">' : '<a target="'+ ad.target + '" href="'+ url +'"><img alt="advertisement" title="' + utils.setTitle(ad.width, ad.height, ad.type) + '" class="img-responsive" width="'+ ad.width +'" height="'+ ad.height +'" src="'+ ad.asseturl +'"></a>';
        } else if (ad.type == 'html') {
            html += '<div style="width: 100%; height: 100%; max-width:'+ad.width+'px; max-height:'+ad.height+'px;">';
            html += ad.html; 
            html += '</div>';
        } else if (ad.type == 'html5') {
            var sSrc = '//' + ad.cdn + '/content/tncms/ads/__html5/' + ad.uuid + '/' + ad.html5_id + '/' + ad.htmlfile;

            if (ad.clickuri) sSrc += '?clickTag='+ encodeURIComponent(ad.clickuri);
            html += '<div style="width: 100%; height: 100%; max-width:'+ad.width+'px; max-height:'+ad.height+'px;">';
            html += '<iframe id="html5-ad-frame-'+ad.uuid+'" width="'+ad.width+'" height="'+ad.height+'" marginwidth="0" marginheight="0" hspace="0" vspace="0" frameborder="0" scrolling="no" src="'+sSrc+'">';
            html += '</iframe>';
            html += '</div>';
        }

        html += '</div>';

        function blackOutDivClick() {
            $('#blox-ad-position-_popup1').remove();
            $('#BlackOutDiv').remove();
        }

        if (typeof oBlackOutDiv.addEventListener != 'undefined') {
            oBlackOutDiv.addEventListener('click', blackOutDivClick, false); // W3C
        } else {
            if (d.compatMode == 'BackCompat') {
                oBlackOutDiv.style.width = '110%';
                oBlackOutDiv.style.height = '110%';
                oBlackOutDiv.style.position = 'absolute';
            }
            oBlackOutDiv.attachEvent('onclick', blackOutDivClick);
        }

        d.getElementsByTagName('body')[0].appendChild(oBlackOutDiv);
        TNCMS.AdManager.renderAd(ad);
        utils.paint(ad, html);
    };

    utils = {

        getAnimationSpeed: function(speed) {
            switch(speed) {
                case 'slow':
                    return 1000;
                case 'medium':
                    return 500;
                case 'fast':
                    return 250;
                case 'instant':
                    return 0;
                default:
                    return 500;
            }
        },

        overline: function(ad) {
            var adOverline;

            if ($(ad.positionDiv).closest('.tncms-region-ads').prev().attr('data-tnt-ads-tmp')) {
                adOverline = $(ad.positionDiv).closest('.tncms-region-ads').prev().data('tnt-ads-tmp').overline;
            } else if ($(ad.positionDiv).attr('data-tnt-ads')) {
                adOverline = $(ad.positionDiv).data('tnt-ads').overline;
            }
            return adOverline;
        },

        paint: function(ad, html) {
            var dfd = new $.Deferred(),
                classArray = ['tnt-ads', 'tnt-' + ad.type + '-ad-wrapper'],
                adClasses = '';

            if (ad.display == 'anchor') {
                classArray.push('tnt-ads-anchor', 'alert');
            } else if (ad.display == 'popup') {
                classArray.push('tnt-ads-popup');
            }

            adClasses = classArray.join(' ');

            if (ad.display == 'wallpaper') {
                $("#wallpaper-left")
                    .empty()
                    .append(html);
                $("#wallpaper-right")
                    .empty()
                    .append(html);
            } else if (ad.display == 'popup') {
                $(ad.positionDiv)
                   .empty()
                   .removeAttr('style')
                   .removeClass()
                   .addClass(adClasses)
                   .css({
                       'max-width': ad.width+'px',
                       'max-height': ad.height+'px'
                   })
                   .append(html);
            } else {
                $(ad.positionDiv)
                    .empty()
                    .removeAttr('style')
                    .removeClass()
                    .addClass(adClasses)
                    .css({
                        'max-width': ad.width + 'px'
                     })
                    .append(html);

                    // Add negative margin for iPhone 5 edge case.
                    if ($(w).width() == 320 && ad.width <= 320 && (ad.width > $(ad.positionDiv).width())) {
                        var negativePadding = Math.round(($(ad.positionDiv).width() - ad.width) / 2);
                        $(ad.positionDiv)
                            .css({
                                'margin': '0 ' + negativePadding + 'px'
                             });
                    }
            }

            if (ad.type != 'text') {
                var adOverline = utils.overline(ad);

                if (adOverline && $(ad.positionDiv).siblings('.tnt-ads-overline').length < 1) {
                    var overlineHtml = '<div class="tnt-ads-overline">' + adOverline + '</div>';
                    $(ad.positionDiv).before( overlineHtml );
                }
            }

            dfd.resolve(ad);
            return dfd.promise();
        },

        setAnimation: function(ad, override) {
            var dfd = new $.Deferred(),
                $bigAd = $(ad.positionDiv).find('.tnt-expandable-big'),
                speed = override === 0 ? override : utils.getAnimationSpeed(ad.transition_speed); // TODO: Do something better for this piece of override check.

            switch(ad.overlay_content) {
                case '0':
                    $bigAd.addClass("noOverlay");
                    break;
                case '1':
                    $bigAd.addClass("overlay");
                    break;
            }

            switch(ad.transition_direction) {
                case 'left':
                    $bigAd
                        .css({
                            'position': 'absolute',
                            'top': 0,
                            'z-index': 999,
                            'right': ad.width
                        })
                        .stop()
                        .animate({
                            width: 'toggle'
                        }, speed);
                    break;
                case 'right':
                    $bigAd
                        .css({
                            'position': 'absolute',
                            'top': 0,
                            'left': ad.width,
                            'z-index': 999
                        })
                        .stop()
                        .animate({
                            width: 'toggle'
                        }, speed);
                    break;
                case 'up':
                    $bigAd
                        .css({
                            'position': 'absolute',
                            'bottom': ad.height,
                            'left': 0,
                            'z-index': 999
                        })
                        .stop()
                        .slideToggle(speed);
                    break;
                default:
                    $bigAd
                        .css({
                            'left': 0,
                            'z-index': 999
                        })
                        .stop()
                        .slideToggle(speed);
                    break;
            }

            dfd.resolve(ad);
            return dfd.promise();
        },

        setLink: function(ad) {
            var dfd = new $.Deferred();

            if (ad.clickuri) {
                $(ad.positionDiv).find('.tnt-expandable-big img').wrap('<a target="_blank" rel="noopener" href="' + ad.clickuri + '"></a>');
                if (ad.start_open != 0) $(ad.positionDiv).find('.tnt-expandable-small img').wrap('<a target="_blank" rel="noopener" href="' + ad.clickuri + '"></a>');
            }

            dfd.resolve(ad);
            return dfd.promise();
        },

        setTitle: function(width, height, type) {
            return width.toString() + 'x' + height.toString() + ' ' + type + ' ad';
        },

        setTrigger: function(ad) {
            var dfd = new $.Deferred();

            switch(ad.start_open) {

                // Open on load.
                case '1':
                    // Open the ad.
                    utils.setAnimation(ad, 0);

                    // Let auto_limit close the ad.
                    if (ad.auto_limit !== '0') {
                        setTimeout(function() {
                            utils.setAnimation(ad);
                        }, ad.auto_limit * 1000);

                    // User will have to click the small ad to close the big ad.
                    } else {
                        $(ad.positionDiv).find('.tnt-expandable-small').on('click', function() {
                            utils.setAnimation(ad);
                        });
                    }
                    break;

                // Open on hover
                case '2':
                    // Set animation whenever mouse goes in and out.
                    $(ad.positionDiv).on('mouseover mouseout', function() {
                        utils.setAnimation(ad);
                    });
                    break;

                // Open on load and hover
                case '3':
                    // Open the ad.
                    utils.setAnimation(ad, 0);
                    $(ad.positionDiv).find('.tnt-expandable-small').addClass('click-to-close');

                    // Let auto_limit close the ad.
                    if (ad.auto_limit !== '0') {
                        setTimeout(function() {
                            utils.setAnimation(ad);
                        }, ad.auto_limit * 1000);
                        setTimeout(function() {
                            $(ad.positionDiv).on('mouseover mouseout', function() {
                                utils.setAnimation(ad);
                            });
                        }, (ad.auto_limit * 1000) + 500);

                    // User will have to click the small ad to close the big ad.
                    } else if ($(ad.positionDiv).find('.tnt-expandable-small').hasClass('click-to-close')) {
                        $(ad.positionDiv).find('.tnt-expandable-small').on('click hover', function() {
                            utils.setAnimation(ad);
                        });
                    }
                    break;

                default:
                    $(ad.positionDiv).find('.tnt-expandable-small').on('click', function() {
                        utils.setAnimation(ad);
                    });
                    break;
                }

            dfd.resolve(ad);
            return dfd.promise();
        },

        setWrapper: function(ad) {
            var dfd = new $.Deferred();

            $(ad.positionDiv).css('position', 'relative');

            dfd.resolve(ad);
            return dfd.promise();
        }

    };

})(window, document, jQuery, __tnt);
